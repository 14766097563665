<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div>
          <!-- 统计 -->
          <div class="data-statistics">
            <div class="item">
              <div class="br">
                <div class="title">账户余额</div>
                <div class="money">
                  <span class="red">{{ balanceInfo.balance }}</span>元
                </div>
              </div>
              <!-- <br />   -->
              <!-- <top-up /> -->
              <span class="btm" @click="toRecharge">马上充值</span>
            </div>
            <div class="item">
              <div class="br">
                <div class="title">可提现余额</div>
                <div class="money">
                  <span class="blue">{{ balanceInfo.ok_money }}</span>元
                </div>
              </div>
              <span class="btm" @click="showTixian">立即提现</span>
            </div>
            <div class="item">
              <div class="br">
                <div class="title">提现中余额</div>
                <div class="money">
                  <span>{{ balanceInfo.tixian_money }}</span>元
                </div>
              </div>
            </div>
            <div class="item">
              <div class="four">
                <div class="title">总提现金额</div>
                <div class="money">
                  <span>{{ balanceInfo.total_money }}</span>元
                </div>
              </div>
            </div>
          </div>
          <!-- 充值Dialog -->
          <div class="rechargeDialog">
            <el-dialog title="余额充值" width="720px" :visible.sync="dialogVisible" @close="closePayDialog" :close-on-click-modal="false" :close-on-press-escape="false">
              <div class="content">
                <div class="name">
                  充值账户：<span>{{ accountzi }}</span>
                </div>
                <div class="price">
                  充值金额：
                  <div class="packing">
                    <div @click="selectAmount(index, item)" class="packing-item" :class="{ active: selectAmountIndex === index }" v-for="(item, index) in rechargeAmount" :key="index">
                      {{ item }}元
                      <img class="dagou" src="~assets/icons/dagou.png" />
                    </div>
                  </div>
                </div>
                <div class="input-num">
                  <div>
                    <el-input-number @mouseenter.native="controls = true" @mouseleave.native="controls = false" placeholder="请输入充值金额,最低50元" :controls="controls" width="300" ref="numInput" v-model="num" size="small" :precision="2" @change="handleChange" :min="50"></el-input-number>
                  </div>
                  <span class="queding">确定</span>
                </div>
                <div>
                  <div class="payway-btn">
                    <span>支付方式：</span>
                    <div class="way">
                      <div class="payway" @click="selectAmount1(index, item.value)" :class="{ active: selectzhi === index }" v-for="(item, index) in rechargezhi" :key="index">
                        <img class="yue" src="~assets/icons/yue.png" />
                        <img class="dagou" src="~assets/icons/dagou.png" />
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                    <el-link type="success">推荐使用支付宝支付</el-link>
                  </div>
                </div>
                <div class="input-num">
                  <span @click="confirmRecharge" class="btn">确认充值</span>
                </div>
              </div>
            </el-dialog>
          </div>
          <!-- 提现Dialog -->
          <div class="tixianDialog">
            <el-dialog title="资金提现" width="560px" :visible.sync="tixianDialogVisible" @close="closeTixianDialog">
              <div class="content">
                <div class="input-item">
                  <span>到帐账号：</span>
                  <div class="zhxg">
                    <span v-if="alipay">{{ '支付宝' + hideCenterPhone(alipay) + '（' + hideStartName(trueName) + '）' }}</span>
                    <span @click="clickSet">点击修改</span>
                  </div>
                </div>
                <div class="input-item number">
                  <span>提现金额：</span>
                  <el-input-number v-model="tixianNum" @mouseenter.native="tixianControls = true" @mouseleave.native="tixianControls = false" :precision="2" controls-position="right" :controls="tixianControls"></el-input-number>
                </div>
                <div class="btn-item">
                  <el-button @click="withdrawal" type="primary">立即提现</el-button>
                </div>
              </div>
            </el-dialog>
          </div>
          <!-- Dialog xiaomao2-->
          <div class="recharge">
            <el-dialog :visible.sync="redialogVisible" :before-close="handleClose" width="60%">
              <div class="recharge-hint">
                <div>使用须知</div>
                <p>
                  1.请使用支付宝扫描左侧收款码进行付款,付款前请核对收款人信息:<span style="color: red">&nbsp;{{ $store.getters.getSiteInfo.alipay_account }}</span>
                </p>
                <p>2.在付款页面备注一栏填写您的登录账号,可实现自动到账!如未备注账号,可从支付宝付款详情中手动复制20开头的订单号,手动提交订单号也可自动到账.</p>
                <p>3.平台暂不支持退款,请根据需要充值.</p>
              </div>
              <div class="recharge-title"><span class="recharge-title-tag" style="color: red">|</span> <span class="recharge-title-text" style="color: black">账户充值</span></div>
              <div class="recharge-main">
                <div class="recharge-main-img"><img :src="imgUrl" /></div>
                <div class="recharge-main-right">
                  <div class="main-right-balance">
                    <i class="el-icon-s-cooperation"></i><span>账户可用金额</span>
                    <p>¥{{ balanceInfo.balance }}</p>
                    <div class="main-right-freshen">
                      <el-button size="mini" plain @click="renovate" style="background-color: white; border-color: red; color: red; font-size: 14px">刷新</el-button>
                    </div>
                  </div>
                  <el-input v-model="input" placeholder="请输入支付宝订单号"></el-input>
                  <el-button style="width: 100%; margin-top: 20px" type="danger" @click="submit">提交充值</el-button>
                </div>
              </div>
            </el-dialog>
          </div>
          <!-- 表格 -->
          <div class="table">
            <div class="table-menu">
              <!-- <div> -->
              <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
                <el-menu-item index="4">提现记录</el-menu-item>
              </el-menu>
              <div class="input-btn">
                <el-input class="el-input" v-model="id" placeholder="查询ID"></el-input>
                <el-button @click="clickQuery" type="primary" size="small">查询</el-button>
                <el-button @click="clickReset" size="small">重置</el-button>
              </div>
            </div>
            <!-- 内容 -->
            <div class="table-main">
              <el-table :data="moneyObj.data" class="el-table-main" :header-cell-style="{ background: '#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500' }">
                <el-table-column prop="money" label="充值金额" width="100px"></el-table-column>
                <el-table-column prop="reason" label="类型" width="120px"></el-table-column>
                <el-table-column prop="after" label="充值后余额" width="100px"></el-table-column>
                <el-table-column prop="memo" label="描述"></el-table-column>
                <el-table-column prop="order_no" label="流水号"></el-table-column>
                <el-table-column label="创建时间" width="160px">
                  <template slot-scope="scope">{{ timeConversion(scope.row.createtime) }}</template>
                </el-table-column>
              </el-table>
              <div class="pagination">
                <el-pagination background @current-change="currentChange" :page-size="moneyObj.per_page" layout="prev, pager, next" :current-page="moneyObj.current_page" :total="moneyObj.total"> </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { topuprice, moneyInfo, moneylist, getUserMoney, balanceApply, getSubTrad } from 'network/api'
import { timeConversion, hideCenterPhone, hideStartName } from 'utils/utils'
// import {topup} from "../../../network/api";
import TopUp from '../../../components/TopUp.vue'
export default {
  components: {
    AppMain,
    TopUp
  },
  inject: ['mainReload', 'reload'],
  data () {
    return {
      redialogVisible: false,
      activeIndex: '0',
      dialogVisible: false, // 余额充值
      tixianDialogVisible: false,
      rechargeAmount: ['100', '300', '500', '1000'],
      rechargezhi: [
        {
          name: '支付宝支付',
          value: 'alipay'
        },
        {
          name: '微信支付',
          value: 'wechat'
        }
      ],
      amountType: 'alipay',
      selectAmountIndex: 1,
      selectzhi: 0,
      num: 50, // undefined
      tixianNum: 1,
      controls: false,
      tixianControls: false,
      balanceInfo: {},
      moneyObj: {},
      alipay: '',
      accountzi: '',
      trueName: '',
      currentPage: 1,
      moneylogId: '',
      reasonVal: '',
      id: '',
      input: '',
      imgUrl: ''
    }
  },
  computed: {
    timeConversion () {
      return timeConversion
    },
    hideCenterPhone () {
      return hideCenterPhone
    },
    hideStartName () {
      return hideStartName
    }
  },
  created () {
    if (this.selectAmountIndex === 1) {
      this.num = 300
    }
    // 获取余额信息
    moneyInfo().then((res) => {
      this.balanceInfo = res.data
    })
    this.selectChange('4')
    // 获取金钱列表
    this.getMoneylist()
    // 获取支付宝账号
    getUserMoney().then((res) => {
      if (res.code !== 1) {
        return this.$message.warning(res.msg)
      }
      this.accountzi = res.data.username
      this.alipay = res.data.ali_pay
      this.trueName = res.data.true_name
    })
  },
  methods: {
    // 提交充值按钮
    submit () {
      getSubTrad({ trade: this.input }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: '充值成功',
            type: 'success'
          })
        } else {
          return this.$message.error({
            message: res.msg
          })
        }
      })
    },
    // 刷新按钮
    renovate () {
      moneyInfo().then((res) => {
        if (res.code === 1) {
          this.balanceInfo = res.data
          this.$message({
            message: '刷新成功',
            type: 'success'
          })
        } else {
          this.$message.error({
            message: '刷新失败'
          })
        }
        // console.log(res)
      })
    },
    // 马上充值
    showRecharge () {
      this.dialogVisible = true
    },
    recharge () {
      this.redialogVisible = true
      this.imgUrl = this.$store.getters.getSiteInfo.alipay_paymentcode
    },
    toRecharge() {
      this.$router.push('/account/recharge')
    },
    // 选择充值金额点击
    selectAmount (index, item) {
      this.selectAmountIndex = index
      this.num = item
    },
    // 充值方式
    selectAmount1 (index, value) {
      this.selectzhi = index
      this.amountType = value
    },
    // 关闭Dialog回调
    closePayDialog () {
      this.refresh()
    },
    refresh () {
      this.reload()
    },
    // 计数器值改变
    handleChange (val) { },
    // 确认充值
    confirmRecharge () {
      if (this.num) {
        topuprice(this.num.toString(), this.amountType).then((res) => {
          if (res.code !== 200) {
            return this.$message(res.msg)
          }
          if (res.code === 200) {
            window.open(res.data.payUrl, '_blank')
          }
        })
      } else {
        this.$message({
          message: '请输入金额',
          type: 'warning'
        })
      }
    },
    // 关闭提现Dialog回调
    closeTixianDialog () { },
    // 提现点击
    showTixian () {
      this.tixianDialogVisible = true
    },
    // 点击修改
    clickSet () {
      this.$router.push('/account/base')
    },
    // 获取金钱变动列表
    getMoneylist () {
      moneylist({
        reason: this.reasonVal,
        moneylog_id: this.moneylogId,
        page: this.currentPage
      }).then((res) => {
        this.moneyObj = res.data
      })
    },
    currentChange (val) {
      this.currentPage = val
      this.getMoneylist()
    },
    selectChange (index) {
      this.currentPage = 1
      switch (index) {
        case '0':
          this.reasonVal = ''
          this.getMoneylist()
          break
        case '1':
          this.reasonVal = '充值'
          this.getMoneylist()
          break
        case '2':
          this.reasonVal = '下单支付'
          this.getMoneylist()
          break
        case '3':
          this.reasonVal = '邀请奖励'
          this.getMoneylist()
          break
        case '4':
          this.reasonVal = '提现'
          this.getMoneylist()
          break
        case '5':
          this.reasonVal = '管理员转账'
          this.getMoneylist()
          break
        case '6':
          this.reasonVal = '退款'
          this.getMoneylist()
          break
      }
    },
    // 查询
    clickQuery () {
      this.moneylogId = this.id
      this.getMoneylist()
    },
    // 重置
    clickReset () {
      this.mainReload()
    },
    // 余额提现
    withdrawal () {
      if (this.tixianNum > this.balanceInfo.ok_money) {
        this.$message({
          message: '可提现余额不足',
          type: 'warning'
        })
        return false
      }
      balanceApply({
        money: this.tixianNum,
        account: this.alipay,
        name: this.trueName
      }).then((res) => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.mainReload()
        }
      })
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => { })
    }
  }
}
</script>

<style scoped src="styles/views/account/cashout.css">
</style>

<style scoped lang="scss">
$colors: #e8e8e8;
$colour: red;
@mixin public($colors) {
  color: $colors;
}
@mixin titles() {
  font: {
    size: 16px;
    weight: 600;
  }
}
.recharge-hint {
  border: 1px solid $colors;
  padding: 15px 15px 0 15px;
  div {
    padding-bottom: 15px;
    font-weight: 600;
    color: black;
    font: {
      size: 16px;
      weight: 600;
    }
  }
  p {
    padding-bottom: 10px;
    @include public(black);
  }
}
.recharge-title {
  margin-top: 15px;
  &-tag {
    @include titles();
  }
  &-text {
    @include titles();
  }
}
.recharge-main {
  display: flex;
  &-img {
    padding: 5% 10% 10% 10%;
    border-right: 1px solid $colors;
    img {
      width: 200px;
      height: 200px;
      border-radius: 10px;
      border: 5px solid #ee7e31;
    }
  }
  &-right {
    width: 100%;
    padding: 40px;
    .main-right-balance {
      padding: 10px 20px 10px 15px;
      background-color: #fff6f7;
      border: 1px solid $colour;
      border-radius: 10px;
      i {
        font-size: 30px;
        color: $colour;
      }
      p {
        color: $colour;
        font-weight: 600;
        margin-top: 10px;
      }
      .main-right-freshen {
        float: right;
        position: relative;
        top: -40px;
      }
    }
  }
}

.rechargeDialog .el-dialog__header,
.tixianDialog .el-dialog__header {
  border-bottom: 1px solid $colors;
}
 .input-num .el-input-number--small {
  width: 180px;
}
.input-item.number .el-input-number {
  width: 324px;
}
</style>
